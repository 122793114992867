import React from "react";
import Helmet from "react-helmet";

import { Col, Row, Menu, Collapse } from "antd";

import Header from "@components/header";
import Footer from "@components/footer";

import TextCenterBanner from "../../components/textCenterBanner";

import * as styles from "./index.module.scss";

import pcImg from "@src/images/feature/Rectangle4386.png";
import phoneImg from "@src/images/feature/Rectangle4387.png";

// import pcImg from "@src/images/aboutMe/飞书20210917-160432.png";
// import phoneImg from "@src/images/aboutMe/飞书20210917-160405.png";

import headerBg from "@src/images/feature/飞书20210914-143446.png";

//图标引入
import ThemeIcon1 from "@src/images/feature/列表视图_list-view 1.png";
import ThemeIcon2 from "@src/images/feature/图片_picture 1.png";
import ThemeIcon3 from "@src/images/feature/信息_message 1.png";
import ThemeIcon4 from "@src/images/feature/双卡片_card-two 1.png";
import ThemeIcon5 from "@src/images/feature/应用_application-two 1.png";
import ThemeIcon6 from "@src/images/feature/图形设计_graphic-design 1.png";

import ConstructIcon1 from "@src/images/feature/翻译_translate 1.png";
import ConstructIcon2 from "@src/images/feature/分类管理_category-management 1.png";
import ConstructIcon3 from "@src/images/feature/设置_setting-one 1.png";
import ConstructIcon4 from "@src/images/feature/列表_list 1.png";
import ConstructIcon5 from "@src/images/feature/商品_commodity 1.png";
import ConstructIcon6 from "@src/images/feature/美化_optimize 1.png";

import ManagementIcon1 from "@src/images/feature/人员_people 1.png";
import ManagementIcon2 from "@src/images/feature/应用_application 1.png";
import ManagementIcon3 from "@src/images/feature/订单_order 1.png";
import ManagementIcon4 from "@src/images/feature/站点加速_whole-site-accelerator 1.png";

import TransformIcon1 from "@src/images/feature/Group.png";
import TransformIcon2 from "@src/images/feature/闪付_flash-payment 1.png";
import TransformIcon3 from "@src/images/feature/优惠券_coupon 1.png";
import TransformIcon4 from "@src/images/feature/脸书_facebook 1.png";

import MobileIcon1 from "@src/images/feature/数据图_chart-pie-one 1.png";
import MobileIcon2 from "@src/images/feature/文件夹-关_folder-close 1.png";
import MobileIcon3 from "@src/images/feature/编辑文件1_file-editing-one (1) 1.png";
import MobileIcon4 from "@src/images/feature/同步_sync 1.png";
import MobileIcon5 from "@src/images/feature/自选_optional 1.png";
import MobileIcon6 from "@src/images/feature/笔记_notes 1.png";

const ListData = [
  {
    title: "VOGUE主题",
    details: [
      {
        leftIcon: ThemeIcon1,
        textHeader: "自带弹窗模块的主题，支持订阅表单和内容分离",
        textNormal:
          "既可以当订阅弹窗收集更多的顾客邮箱，还可以隐藏表单，作为网站弹窗通知展示给顾客。",
      },
      {
        leftIcon: ThemeIcon2,
        textHeader: "支持图片、文字、链接自由搭配的品牌宣传模块",
        textNormal:
          "背景可以添加图片，内容也可以添加图片；按钮可以添加链接，文案也可以添加链接。",
      },
      {
        leftIcon: ThemeIcon3,
        textHeader: "商品评论模块，支持展示店铺最新的商品评论",
        textNormal:
          "当店铺中有新的商品评论时会展示最新的商品评论，可点击查看并跳转到对应的商品详情页，进一步提高曝光量。",
      },
      {
        leftIcon: ThemeIcon4,
        textHeader: "支持首页、列表页、详情页、结账页、产品卡片细致装修",
        textNormal:
          " 商品详情页独家支持宽屏和窄屏显示，用户可以根据自己商品的特点选择合适的选项，更完美地展现自己店铺。",
      },
      {
        leftIcon: ThemeIcon5,
        textHeader: "个性化的图文、精选商品、视频等模块，满足各个行业需求",
        textNormal:
          " 图文模块除了一般的左右图文，还有多种个性混合布局模式，支持PC端和移动端单独配置图片，为您留下充足的想象空间。",
      },
      {
        leftIcon: ThemeIcon6,
        textHeader: "",
        textNormal:
          "注重移动端体验，专业排版优化，支持banner等移动端单独配置；支持四个层级、图文并茂的导航；支持12个卡片20种排版模式；支持文字、图片、轮播、弹窗公告；融合社交元素，便捷分享、社交主页展示、Instagram帖子展示及相关商品推荐；丰富便捷的页脚编辑，支持添加菜单、图文、订阅、店铺信息；时尚鞋服、3C数码、家居、宠物、户外、汽配……精美模板一键使用，解放装修压力是为了更好地聚力于生意。",
      },
    ],
  },
  {
    title: "店铺搭建",
    details: [
      {
        leftIcon: ConstructIcon1,
        textHeader: "多语言&多货币",
        textNormal:
          "支持10余种店铺语言，支持根据买家IP地址自动切换货币， 满足商品需要销往多个国家的需求，解决不同地区的销售政策差异化等问题；支持不同国家模板手工修改，支持卖家手动设置语言和货币， 针对不同国家/地区展示适应当地的装修风格、语言和货币， 并针对当地支付习惯进行收款，实现一店分身全球销售。",
      },
      {
        leftIcon: ConstructIcon2,
        textHeader: "业内最强大的商品管理功能",
        textNormal:
          "一键导品，看上的东西再多也能一网打尽；一键搬迁，只需要动下手指，剩下的我们帮您完成；商品多维度检索，方便更好地组合营销；商品属性批量调整，解放您的大脑和时间。",
      },
      {
        leftIcon: ConstructIcon3,
        textHeader: "最全面的属性设置",
        textNormal:
          "为您的产品提供不同的属性设置，例如多种尺码、颜色、材质等；每种属性都可以独立设置相关的价格、SKU、重量和库存。 ",
      },
      {
        leftIcon: ConstructIcon4,
        textHeader: "商品的分类管理",
        textNormal:
          "支持按类别、类型、季节、促销等条件整理商品；支持根据供应商、价格和库存自动对产品进行分类。 ",
      },
      {
        leftIcon: ConstructIcon5,
        textHeader: "商品展示多样化",
        textNormal:
          " 支持商品VR试穿，试穿好了再购买，有效降低退货概率；支持商品360°旋转展示，更好地呈现商品的全部细节；支持商品多图展示，打造精美的商品详情页。",
      },
      {
        leftIcon: ConstructIcon6,
        textHeader: "商品SEO优化",
        textNormal:
          " 通过使用产品特定的SEO优化能力，对单件商品进行引流打造爆款。",
      },
    ],
  },
  {
    title: "店铺管理",
    details: [
      {
        leftIcon: ManagementIcon1,
        textHeader: "顾客管理与顾客通知",
        textNormal:
          "支持顾客名单导入导出，快速录入新老客户；支持顾客按照条件筛选查找快速定位；支持下单、退单、发货、退货、退款、确认等全流程通知；通知模板多样化个性化设置。",
      },
      {
        leftIcon: ManagementIcon2,
        textHeader: "店铺权限管理",
        textNormal:
          "全体应用可单独设置员工权限；快速定制员工岗位匹配权限；交叉权限自动解耦或关联启闭",
      },
      {
        leftIcon: ManagementIcon3,
        textHeader: "订单系统",
        textNormal:
          "科学划分订单呈现结构，让值得关注的信息优先关注；支持查询订单、添加运单号、查看物流详情、更新物流状态等全流程操作。",
      },
      {
        leftIcon: ManagementIcon4,
        textHeader: "多域名&店铺SEO",
        textNormal:
          " 支持一店多域名绑定；支持设置站点地图；店铺SEO结构化的资料优化、包括商品、店铺的SEO关键词、描述、关联性URL等优化。",
      },
    ],
  },
  {
    title: "转化利器",
    details: [
      {
        leftIcon: TransformIcon1,
        textHeader: "社交平台获取天然流量",
        textNormal:
          "支持Google Feed；支持Facebook Feed；支持Facebook Pixel 数据追踪；支持Google Analytics 数据追踪；支持Google Ads 数据追踪；支持TikTok Pixel 数据追踪；支持Hotjar 用户行为追踪；支持Lucky Orange 用户行为追踪。",
      },
      {
        leftIcon: TransformIcon2,
        textHeader: "结账流程优化",
        textNormal:
          "支持Google地址自动补全，减少顾客信息填写时长，加速结算流程；支持Paypal快捷支付，减少结账步骤；支持单页结账，优化结账环节减少页面跳转；结账页交互时间低至1s，保障结账效率，有效提升转化。",
      },
      {
        leftIcon: TransformIcon3,
        textHeader: "各类营销手段全面提升转化率 ",
        textNormal:
          "满减活动——刺激用户主动凑单提升销量；最近售卖弹窗——实时显示商品访客数、弹窗浮动显示最近被购买的商品，营造紧迫感，刺激下单；快捷加购——顾客可在下滑浏览的过程中便捷加购商品；优惠码——提供多种优惠码获取途径，提高优惠码使用率，诱导顾客消费；弹窗&公告栏——支持促销活动通知、邮件订阅通知、包邮通知、浮窗通知和邮件群发推荐商品——使其展示在其他商品的详情页中，以提高客单价和转化；组合销售——将不同的商品组合在一起进行销售，给予适当优惠，刺激用户凑单。 而我们的广告助手可以实时获取FB广告订单数据，呈现精准的广告投放数据，把握市场瞬态。",
      },
      {
        leftIcon: TransformIcon4,
        textHeader: "Facebook广告助手",
        textNormal:
          "由于隐私政策影响，Facebook官方助手只查看两到三天前的数据，严重影响运营人员对广告投放效果的判断。 而我们的广告助手可以实时获取FB广告订单数据，呈现精准的广告投放数据，把握市场瞬态。",
      },
    ],
  },
  {
    title: "移动端APP",
    details: [
      {
        leftIcon: MobileIcon1,
        textHeader: "数据查看",
        textNormal:
          "支持在APP上快速查看店铺运营数据，包括商品、客户、订单、营销等数据、并支持实时查看店铺访客和GMV信息。",
      },
      {
        leftIcon: MobileIcon2,
        textHeader: "库存管理",
        textNormal:
          "支持添加商品、下架商品、拍摄商品照片等对商品进行编辑的操作。",
      },
      {
        leftIcon: MobileIcon3,
        textHeader: "订单处理 ",
        textNormal:
          "支持在APP上查看订单详情，并对订单状态、付款状态、发货状态进行变更。",
      },
      {
        leftIcon: MobileIcon4,
        textHeader: "数据同步 ",
        textNormal:
          "当有用户新建订单、商品售罄的时候系统会将数据信息同步至移动端。",
      },
      {
        leftIcon: MobileIcon5,
        textHeader: "数据总览界面 ",
        textNormal:
          "基础指标有访问用户、订单量、成交额、转化率；转化漏斗有订单总成交额、订单转化率、访问用户数。",
      },
      {
        leftIcon: MobileIcon6,
        textHeader: "流量报告",
        textNormal:
          "通过流量报告，可以查看并得知访问者来源以及他们到店的方式。 ",
      },
    ],
  },
  {
    title: "生态应用",
    details: [],
  },
];

const tableData = [
  {
    name: "商品管理",
    intro: "帮助运营管理店铺商品及商品销售渠道相关的应用",
    details: [
      "复制店铺",
      "谷歌Feed同步",
      "Facebook Feed",
      "自定义按钮",
      "Affiliatly",
    ],
  },
  {
    name: "营销推广",
    intro:
      "通过邮件或其他方式的内容营销，以及广告及搜索引擎优化来提升店铺品牌的应用",
    details: [
      "Mailchimp",
      "Klaviyo",
      "营销弹窗",
      "智能营销",
      "谷歌Sitemap",
      "URL重定向",
      "Pixel Conversion",
    ],
  },
  {
    name: "运营转化",
    intro: "激励客户进行购买，有助于提高转化率和客单价的应用",
    details: [
      "最近售卖弹窗",
      "分享激励",
      "访客计数器",
      "快捷加购",
      "组合商品",
      "EXTRA优惠码",
      "OptinMonster",
      "运费险",
      "推荐商品",
      "款式组合",
      "商品置顶",
      "促销倒计时",
    ],
  },
  {
    name: "订单管理",
    intro: "帮助商家处理订单或方便告知客户订单状态的应用",
    details: ["马帮ERP", "斑马ERP", "Order Tracking"],
  },
  {
    name: "店铺设计",
    intro: "为客户店铺装修设计支持更多样式和功能的应用",
    details: [
      "商品规格调色板",
      "结账页信息栏",
      "商品评论",
      "第三方登录",
      "产品过滤器",
      "历史浏览记录",
      "产品卡片标签",
      "Instagram 展示",
      "尺码表",
    ],
  },
  {
    name: "客户服务",
    intro: "方便商家为客户解决售前售后问题的应用",
    details: ["Tidio", "绑定 Messenger"],
  },
  {
    name: "店铺管理",
    intro: "协助维护店铺经营或店铺业务相关的技术工作的应用",
    details: ["地区访问限制", "自定义代码嵌入", "Shopify backup"],
  },
];

const FeaturePage = () => {
  return (
    <div className={styles.featuresPage}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>功能特性-Shoprise 官网</title>
      </Helmet>
      <Header />
      <TextCenterBanner pcImg={pcImg} phoneImg={phoneImg} text="功能特性" />

      <Row>
        {/* pc端 */}
        <Col xs={0} sm={0} md={24}>
          <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
            <Menu
              mode="horizontal"
              // defaultSelectedKeys={["0"]}
              style={{
                height: "96px",
                lineHeight: "96px",
                borderBottom: "none",
                justifyContent: "space-between",
              }}
            >
              {ListData.map((item, index) => {
                return (
                  <Menu.Item key={item.title} style={{ padding: "0 50px" }}>
                    <a href={`#${index}`} style={{ fontSize: "18px" }}>
                      {item.title}
                    </a>
                  </Menu.Item>
                );
              })}
            </Menu>
            <div className={styles.listGroup}>
              {/* 第一个主题有点不同 */}
              <div className={styles.listItem} key={ListData[0].title} id={0}>
                <div className={styles.itemHeader}>
                  <img
                    src={headerBg}
                    alt="headerBg"
                    style={{ width: "100%" }}
                  />
                  <span>{ListData[0].title}</span>
                </div>
                <div className={styles.content}>
                  <div className={styles.special1}>
                    VOGUE主题，满足您的想象力
                  </div>
                  {ListData[0].details
                    .filter(
                      (_, index) => index < ListData[0].details.length - 1
                    )
                    .map((subitem) => {
                      return (
                        <div
                          className={styles.contentItem}
                          key={subitem.textHeader}
                        >
                          <div className={styles.imgContainer}>
                            <img src={subitem.leftIcon} alt="aa" />
                          </div>
                          <div className={styles.textContainer}>
                            <div className={styles.textHeader}>
                              {/* 个性化的图文、精选商品、视频等模块，满足各个行业需求 */}
                              {subitem.textHeader}
                            </div>
                            <div className={styles.textNormal}>
                              {/* 支持顾客名单导入导出，快速录入新老客户；支持顾客按照条件筛选查找快速定位；支持下单、退单、发货、退货、退款、确认等全流程通知；通知模板多样化个性化设置。 */}
                              {subitem.textNormal}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <div className={styles.special1}>
                    VOGUE主题，精美的不止设计
                  </div>
                  <div className={styles.contentItem}>
                    <div
                      className={styles.imgContainer}
                      style={{ margin: "0" }}
                    >
                      <img
                        src={
                          ListData[0].details[ListData[0].details.length - 1]
                            .leftIcon
                        }
                        alt="aa"
                      />
                    </div>
                    <div className={styles.textContainer}>
                      <div className={styles.textHeader}>
                        {
                          ListData[0].details[ListData[0].details.length - 1]
                            .textHeader
                        }
                      </div>
                      <div className={styles.textNormal}>
                        {
                          ListData[0].details[ListData[0].details.length - 1]
                            .textNormal
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {ListData.filter((_, index) => index).map((item, index) => {
                return (
                  <div
                    className={styles.listItem}
                    key={item.title}
                    id={index + 1}
                  >
                    {/* 每一个主题里面的内容 */}
                    <div className={styles.itemHeader}>
                      <img
                        src={headerBg}
                        alt="headerBg"
                        style={{ width: "100%" }}
                      />
                      <span>{item.title}</span>
                    </div>
                    <div className={styles.content}>
                      {item.details.map((subitem, subIndex) => {
                        return (
                          <div
                            className={styles.contentItem}
                            key={subitem.textHeader}
                          >
                            <div className={styles.imgContainer}>
                              <img src={subitem.leftIcon} alt="aa" />
                            </div>
                            <div className={styles.textContainer}>
                              <div className={styles.textHeader}>
                                {/* 个性化的图文、精选商品、视频等模块，满足各个行业需求 */}
                                {subitem.textHeader}
                              </div>
                              <div className={styles.textNormal}>
                                {/* 支持顾客名单导入导出，快速录入新老客户；支持顾客按照条件筛选查找快速定位；支持下单、退单、发货、退货、退款、确认等全流程通知；通知模板多样化个性化设置。 */}
                                {subitem.textNormal}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <table className={styles.tableContainer}>
              <thead>
                <tr>
                  <th>分类名称</th>
                  <th>分类说明</th>
                  <th className={styles.tdSpecial}>
                    <div
                      className={styles.tdInnerBox}
                      style={{ border: "none" }}
                    >
                      相关应用
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item) => {
                  return (
                    <tr key={item.name}>
                      <td>{item.name}</td>
                      <td>{item.intro}</td>
                      <td className={styles.tdSpecial}>
                        {item.details.map((item) => (
                          <div className={styles.tdInnerBox} key={item}>
                            {item}
                          </div>
                        ))}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Col>

        {/* 移动端 */}
        <Col xs={24} sm={24} md={0}>
          <Collapse
            // defaultActiveKey={["1"]}
            expandIconPosition="right"
            className={styles.phoneShowBox}
            style={{ marginTop: "20px", border: "none" }}
          >
            {/* 第一个照常有点不一样 */}
            <Collapse.Panel header={ListData[0].title} key={0}>
              <div className={styles.smallSpecial1}>
                VOGUE主题，满足您的想象力
              </div>

              {ListData[0].details
                .filter((_, index) => index < ListData[0].details.length - 1)
                .map((secItem) => {
                  return (
                    <div className={styles.pannelItem} key={secItem.textHeader}>
                      <img src={secItem.leftIcon} alt="" />
                      <div className={styles.textHeader}>
                        {secItem.textHeader}
                      </div>
                      <div className={styles.textNormal}>
                        {secItem.textNormal}
                      </div>
                    </div>
                  );
                })}
              <div className={styles.smallSpecial1}>
                VOGUE主题，精美的不止设计
              </div>
              <div className={styles.pannelItem}>
                <img
                  src={
                    ListData[0].details[ListData[0].details.length - 1].leftIcon
                  }
                  alt=""
                />
                <div className={styles.textHeader}>
                  {
                    ListData[0].details[ListData[0].details.length - 1]
                      .textHeader
                  }
                </div>
                <div className={styles.textNormal}>
                  {
                    ListData[0].details[ListData[0].details.length - 1]
                      .textNormal
                  }
                </div>
              </div>
            </Collapse.Panel>
            {ListData.filter(
              (_, index) => index && index < ListData.length - 1
            ).map((item, index) => {
              return (
                <Collapse.Panel header={item.title} key={index + 1}>
                  {item.details.map((secItem) => {
                    return (
                      <div
                        className={styles.pannelItem}
                        key={secItem.textHeader}
                      >
                        <img src={secItem.leftIcon} alt="" />
                        <div className={styles.textHeader}>
                          {secItem.textHeader}
                        </div>
                        <div className={styles.textNormal}>
                          {secItem.textNormal}
                        </div>
                      </div>
                    );
                  })}
                </Collapse.Panel>
              );
            })}
            <Collapse.Panel
              header={ListData[ListData.length - 1].title}
              key={ListData.length - 1}
            >
              <table className={styles.smallTableContainer}>
                <thead>
                  <tr>
                    <th>分类名称</th>
                    <th>分类说明</th>
                    <th className={styles.tdSpecial}>
                      <div
                        className={styles.tdInnerBox}
                        style={{ border: "none" }}
                      >
                        相关应用
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item) => {
                    return (
                      <tr key={item.name}>
                        <td>{item.name}</td>
                        <td>{item.intro}</td>
                        <td className={styles.tdSpecial}>
                          {item.details.map((item) => (
                            <div className={styles.tdInnerBox} key={item}>
                              {item}
                            </div>
                          ))}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>

      <Footer />
    </div>
  );
};

export default FeaturePage;
