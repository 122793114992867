// extracted by mini-css-extract-plugin
export var content = "index-module--content--yAuBB";
export var contentItem = "index-module--contentItem--5JQdP";
export var featuresPage = "index-module--featuresPage--Dj45i";
export var imgContainer = "index-module--imgContainer--9045n";
export var itemHeader = "index-module--itemHeader--Lng4S";
export var listGroup = "index-module--listGroup--SCH3e";
export var listItem = "index-module--listItem--AiDVK";
export var pannelItem = "index-module--pannelItem--PpF6f";
export var phoneShowBox = "index-module--phoneShowBox--k8v4K";
export var smallSpecial1 = "index-module--smallSpecial1--6EVhl";
export var smallTableContainer = "index-module--smallTableContainer--qqlWV";
export var special1 = "index-module--special1--QeTPk";
export var tableContainer = "index-module--tableContainer--YDR8M";
export var tdInnerBox = "index-module--tdInnerBox--p8wDb";
export var tdSpecial = "index-module--tdSpecial--edRWk";
export var textContainer = "index-module--textContainer--ErSeO";
export var textHeader = "index-module--textHeader--Jo3tR";
export var textNormal = "index-module--textNormal--4NY6C";